<template>
  <v-container id="showMembers" class="bgMezzo mx-0" fluid>
    <v-row class="mt-15" justify="center">
      <v-col class="mt-10" cols="12" md="3">
        <v-card
          style="padding: 15px; border-radius: 15px; background-color: #791833"
          class=""
        >
          <h1
            style="
              font-size: 25px;
              font-family: poppins;
              font-weight: 500;
              color: #fff;
            "
          >
            Olá, {{ user.first_name }}
          </h1>
          <p
            style="
              font-size: 18px;
              font-family: poppins;
              font-weight: 500;
              color: #fff;
            "
          >
            Seu saldo é:
            <span style="font-size: 25px">{{
              (Math.trunc(user.balance * 100) / 100).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })
            }}</span>
            Mezzocoins

            <!-- <span v-if="!balance" style="font-size: 25px">0</span> Mezzocoins -->
          </p>
          <v-btn @click="dialogPerfil = true">Perfil</v-btn>
        </v-card>
      </v-col>
    </v-row>

    <div
      style="height: 2px solid"
      class="border-opacity-100"
      color="791833"
    ></div>

    <v-row style="padding: 60px 10px">
      <v-col cols="12" md="12">
        <h1
          style="
            font-size: 25px;
            font-family: poppins;
            font-weight: 500;
            color: #791833;
          "
        >
          Fique ligado!
        </h1>
      </v-col>

      <v-col
        cols="6"
        md="2"
        v-for="(banner, index) in announcements"
        :key="index"
      >
        <a
          :href="
            banner.link
              ? banner.link
              : `https://mezzo.abare.cloud/assets/${banner.file}`
          "
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-img
            v-if="banner.image"
            :src="`https://mezzo.abare.cloud/assets/${banner.image}`"
            height="10rem"
            contain
          ></v-img>
          <v-icon size="10rem" v-else>mdi-image-off-outline</v-icon>
        </a>
      </v-col>

      <!-- <v-col cols="6" md="2">
        <v-card>
          <img src="../assets/foto2.jpg" style="width: 100%" />
        </v-card>
      </v-col>
      <v-col cols="12" md="2">
        <v-card class="">
          <img src="../assets/foto3.jpg" style="width: 100%" />
          <v-icon @click="
            imgDialog = imgs.img1;
          mostraDialog = true;
          " style="color: #fff; font-size: 54px" class="middle">mdi-magnify-plus-outline</v-icon>
        </v-card>
      </v-col> -->
    </v-row>

    <v-dialog v-model="dialogPerfil" max-width="500px">
      <v-card style="padding: 15px; background-color: #791833">
        <v-card-title>
          <span
            class=""
            style="
              font-size: 25px;
              font-family: poppins;
              font-weight: 500;
              color: #570c22;
              color: #fff;
            "
            >Seus dados</span
          >
        </v-card-title>

        <v-card-text class="mt-5">
          <v-form ref="form" v-model="valid" light style="">
            <v-row
              style="
                width: 100%;
                border: 1px solid #fff;
                padding: 5px;
                border-radius: 10px;
                background-color: #fff;
              "
            >
              <v-col cols="12" class="flex-column">
                <p style="color: #570c22; margin-bottom: 0; font-size: 1rem">
                  Nome:
                </p>
                <p
                  style="color: #570c22; font-size: 1.5rem; margin-bottom: 0"
                  v-if="!editUser"
                >
                  {{ user.first_name }}
                </p>
                <v-text-field
                  v-else
                  style="color: #570c22; font-size: 1.5rem; margin-bottom: 0"
                  v-model="user.first_name"
                  dense
                  :rules="rule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="flex-column">
                <p style="color: #570c22; margin-bottom: 0; font-size: 1rem">
                  Sobrenome:
                </p>
                <p
                  style="color: #570c22; font-size: 1.5rem; margin-bottom: 0"
                  v-if="!editUser"
                >
                  {{ user.last_name }}
                </p>
                <v-text-field
                  v-else
                  style="color: #570c22; font-size: 1.5rem; margin-bottom: 0"
                  v-model="user.last_name"
                  dense
                  :rules="rule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="flex-column">
                <p style="color: #570c22; margin-bottom: 0; font-size: 1rem">
                  Telefone:
                </p>
                <p
                  style="color: #570c22; font-size: 1.5rem; margin-bottom: 0"
                  v-if="!editUser"
                >
                  {{ user.phone }}
                </p>
                <v-text-field
                  v-else
                  style="color: #570c22; font-size: 1.5rem; margin-bottom: 0"
                  v-model="user.phone"
                  dense
                  v-mask="'(##) # #### ####'"
                  :rules="rule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="flex-column">
                <p style="color: #570c22; margin-bottom: 0; font-size: 1rem">
                  E-mail:
                </p>
                <p style="color: #570c22; font-size: 1.5rem; margin-bottom: 0">
                  {{ user.email }}
                </p>
              </v-col>

              <v-col
                cols="12"
                class="d-flex align-center justify-end"
                v-if="editUser"
              >
                <v-btn
                  color="#570c22"
                  style="color: #fff"
                  @click="editUserFields(true)"
                  >Salvar</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn v-if="!editUser" @click="editUser = !editUser">EDITAR</v-btn>
          <v-btn
            color="#fff"
            style="font-family: poppins"
            text
            @click="resetUser"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogData" max-width="500px" persistent>
      <v-card style="padding: 15px; width: 100%; background-color: #791833">
        <v-card-title>
          <span
            class=""
            style="
              font-size: 25px;
              font-family: poppins;
              font-weight: 500;
              color: #570c22;
              color: #fff;
            "
            >Seus dados estão incompletos!</span
          >
        </v-card-title>

        <v-card-text class="mt-5">
          <v-form ref="form" v-model="valid" light style="">
            <v-row
              style="
                width: 100%;
                border: 1px solid #fff;
                padding: 5px;
                border-radius: 10px;
                background-color: #fff;
              "
            >
              <v-col
                cols="12"
                class="d-flex flex-column align-center justify-center"
              >
                <h2 style="color: #570c22; font-size: 1.5rem; margin-bottom: 0">
                  Preencha os dados que faltam!
                </h2>
              </v-col>

              <v-col cols="12" class="flex-column" v-if="missingName">
                <p style="color: #570c22; margin-bottom: 0; font-size: 1rem">
                  Nome:
                </p>
                <v-text-field
                  style="color: #570c22; font-size: 1.5rem; margin-bottom: 0"
                  required
                  v-model="user.first_name"
                  :rules="rule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="flex-column" v-if="missingLastName">
                <p style="color: #570c22; margin-bottom: 0; font-size: 1rem">
                  Sobrenome:
                </p>
                <v-text-field
                  style="color: #570c22; font-size: 1.5rem; margin-bottom: 0"
                  required
                  dense
                  v-model="user.last_name"
                  :rules="rule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="flex-column" v-if="missingBirthday">
                <p style="color: #570c22; margin-bottom: 0; font-size: 1rem">
                  Data de nascimento:
                </p>
                <v-text-field
                  style="color: #570c22; font-size: 1.5rem; margin-bottom: 0"
                  hide-details
                  light
                  dense
                  v-model="user.birthday"
                  v-mask="'##/##/####'"
                  required
                  color="#8e1f3f"
                  :rules="rule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="flex-column" v-if="missingPhone">
                <p style="color: #570c22; margin-bottom: 0; font-size: 1rem">
                  Telefone:
                </p>
                <v-text-field
                  style="color: #570c22; font-size: 1.5rem; margin-bottom: 0"
                  v-model="user.phone"
                  v-mask="'(##) # #### ####'"
                  required
                  dense
                  :rules="rule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="flex-column" v-if="missingDocument">
                <p style="color: #570c22; margin-bottom: 0; font-size: 1rem">
                  CPF:
                </p>
                <v-text-field
                  style="color: #570c22; font-size: 1.5rem; margin-bottom: 0"
                  v-model="user.document"
                  v-mask="'###.###.###-##'"
                  required
                  dense
                  :rules="rule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="d-flex align-center justify-end">
                <v-btn
                  color="#570c22"
                  style="color: #fff"
                  @click="editUserFields"
                  >Salvar</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <!-- <v-card-actions class="justify-end">
          <v-btn
            color="#fff"
            style="font-family: poppins"
            text
            @click="dialogPerfil = false"
            >Fechar</v-btn
          >
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios';
import store from '../store';

export default {
  data: () => ({
    user: store.state.user || null,
    birthday: null,
    valid: false,
    dialogData: false,
    dialogPerfil: false,
    missingName: false,
    missingLastName: false,
    missingBirthday: false,
    missingPhone: false,
    missingDocument: false,
    announcements: null,
    editUser: false,
    rule: [(value) => !!value || 'Este campo é obrigatório.']
  }),
  methods: {
    resetUser() {
      this.user = { ...store.state.user };
      this.dialogPerfil = false;
      this.editUser = false;
      this.$forceUpdate();
    },
    checkMissingFields() {
      if (!this.user.first_name) {
        this.missingName = true;
        this.dialogData = true;
      }
      if (!this.user.last_name) {
        this.missingLastName = true;
        this.dialogData = true;
      }
      if (!this.user.birthday) {
        this.missingBirthday = true;
        this.dialogData = true;
      }
      if (!this.user.phone) {
        this.missingPhone = true;
        this.dialogData = true;
      }
      if (!this.user.document) {
        this.missingDocument = true;
        this.dialogData = true;
      }
    },
    checkFileType(banner) {
      // console.log('banner', banner);
      const type = banner.file?.type?.split('/')[1];
      // console.log(type);
      return type;
    },
    async getAnnouncements() {
      try {
        const {
          data: { data }
        } = await axios.get(
          `/items/announcements?fields=file,image,name,link&filter[status][_eq]=active`,
          {
            headers: {
              Authorization: `Bearer Df1Lordmn0PektIskl5GB5_PjnnVc4aV`
            }
          }
        );

        this.announcements = data;
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(birthday) {
      const formattedBirthday = birthday.split('/');
      return `${formattedBirthday[2]}-${formattedBirthday[1]}-${formattedBirthday[0]}`;
    },
    async editUserFields(isFirstTime) {
      try {
        const updateUserFields = {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          phone: this.user.phone
        };

        if (isFirstTime) {
          updateUserFields.document = this.user.document;
          updateUserFields.birthday = new Date(
            this.formatDate(this.user.birthday)
          );
        }

        for (let key in updateUserFields) {
          if (!updateUserFields[key]) {
            throw new Error('Nenhum campo pode ficar vazio!');
          }
        }

        await axios.patch(
          `https://mezzo.abare.cloud/users/${this.user.id}`,
          updateUserFields
        );

        this.dialogPerfil = false;
        this.dialogData = false;
        this.editUser = false;
      } catch (error) {
        console.log(error);
      }
      console.log('User edited');
    }
  },
  mounted() {
    this.checkMissingFields();
    this.getAnnouncements();
  }
};
</script>

<style scoped>
.middle {
  transition: 0.5s ease;
  opacity: 0;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.conteudo:hover .image {
  opacity: 0.3;
  background-color: #000 !important;
}

.conteudo:hover .middle {
  opacity: 1;
}

.conteudo {
  position: relative;
  width: 50%;
  background-color: #000;
}
</style>

